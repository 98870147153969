@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-datetime-picker/dist/DateTimePicker.css";
@import "react-calendar/dist/Calendar.css";
@import "react-clock/dist/Clock.css";

@import "./scrollbar.css";
@import "./custom.css";
@import "./toast.css";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #e5e5e5;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ck-editor__editable {
  min-height: 300px;
  font-size: 1rem;
}

.cke_chrome {
  border-radius: 10px;
  border: 1px solid #695656;
  border-width: thin;
}

.cke_top {
  border-radius: 10px 10px 0px 0px;
}

.cke_bottom {
  border-radius: 0px 0px 10px 10px;
}

.sun-editor .se-btn {
  font-family: "Inter", sans-serif !important;
}

@media print {
  @page {
    size: A4;
    margin: 19mm 19mm 5mm 19mm;
  }

  div.page-footer,
  div.footer-info {
    display: block !important;
  }
}

table.report-container {
  page-break-after: always;
  width: 100%;
  border: none !important;
}

.report-container tr,
.report-container td {
  border: none !important;
}

tfoot.report-footer {
  display: table-footer-group;
  border: none !important;
}

div.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: rgb(98, 98, 98);
  text-align: center;
  padding: 0;
  font-size: 12px;
}

div.footer-info {
  height: 25px;
}

.react-tel-input {
  font-family: "Inter", sans-serif !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  padding: 8.5px 14px 8.5px 58px !important;
}

.se-component.se-image-container.__se__float-.__se__float-right {
  margin-left: 10px !important;
}

.se-component.se-image-container.__se__float-.__se__float-left {
  margin-right: 10px !important;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.user-sidebar {
  min-height: calc(100vh - 152px);
}

.sun-editor .se-toolbar.se-toolbar-sticky {
  position: static !important;
}

#chatBox * {
  overflow-anchor: none;
}

#chatAnchor {
  overflow-anchor: auto;
  height: 1px;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
